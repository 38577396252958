import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment-timezone';
import jstz from 'jstz';
import CustomCountdown from './common/CustomCountdown';
import { setListingStatusIds } from '../actions';
import {
  commafy,
  getTemplate,
  isDST,
  makeAssetLink,
  getConfig,
} from '../utils/helpers';
import '../assets/css/event-card.css';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 320,
    width: 250,
    border: '1px solid #ccc',
    borderRadius: 6,
    backgroundColor: '#fff',
    margin: 8,
  },
  content: {},
  header: {
    color: '#fff',
    padding: '8px 12px',
    height: 54,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
  },
  imageContainer: {
    padding: 15,
    height: 130,
    cursor: 'pointer',
  },
  image: {
    objectFit: 'contain',
    overflow: 'hidden',
    width: '100%',
    maxHeight: 110,
  },
  eventInfo: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 8,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: 5,
    paddingRight: 5,
    padding: '0 8px 8px 8px',
  },
  button: {
    fontSize: 11,
    marginLeft: 6,
    marginBottom: 3,
    borderRadius: 3,
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    margin: 0,
  },
};

class EventCard extends Component {
  handleCardClick = e => {
    e.preventDefault();
    const searchLink = `/search?eventName[0]=${encodeURIComponent(
      this.props.item.eventName
    )}`;
    this.props.navigate(searchLink);
  };

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    const headerBackgroundColor = getTemplate(
      this.props.template,
      'eventCardHeader.backgroundColor'
    );
    const headerColor = getTemplate(
      this.props.template,
      'eventCardHeader.color'
    );

    return (
      <div
        className='event-card__header'
        style={{
          backgroundColor: headerBackgroundColor,
          color: headerColor,
        }}
        onClick={this.handleCardClick}
      >
        <div>
          <span style={{ marginRight: 10 }} className="fa fa-calendar" />
          <span>{this.props.item.eventName}</span>
        </div>
      </div>
    );
  }

  renderImage() {
    const imageUri = this.props.item.eventLogoUrl || makeAssetLink('logo.png');

    return (
      <div style={styles.imageContainer} onClick={this.handleCardClick}>
        <LazyLoad>
          <img
            src={imageUri}
            style={styles.image}
            alt="event logo"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = makeAssetLink('logo.png');
            }}
            role="presentation"
          />
        </LazyLoad>
      </div>
    );
  }

  renderCounts() {
    const { itemCountAtAuction, itemCountNoSale } = this.props.item;
    const mpFeatures = (this.props.marketplaceFeatures.features || '').split(
      ','
    );

    const atAuctionText = !itemCountAtAuction
      ? ''
      : itemCountAtAuction === 1
      ? `${itemCountAtAuction} At Auction`
      : `${commafy(itemCountAtAuction)} At Auction`;

    const noSalesDisabled = mpFeatures.includes('106');
    const noSalesText =
      !itemCountNoSale || noSalesDisabled
        ? ''
        : itemCountNoSale === 1
        ? `${itemCountNoSale} No Sale`
        : `${commafy(itemCountNoSale)} No Sales`;

    const eventTextColor = getTemplate(
      this.props.template,
      'eventCardText.color'
    );

    return (
      <div style={{ minHeight: 40, cursor: 'pointer' }}>
        {atAuctionText && (
          <div
            style={{ fontWeight: 'bold', color: eventTextColor }}
            onClick={e => {
              this.props.setListingStatusIds([1, 3]);
              this.handleCardClick(e);
            }}
          >
            <span className="fa fa-car" />
            <span style={{ paddingLeft: 6, whiteSpace: 'pre' }}>
              {atAuctionText}
            </span>
          </div>
        )}

        {noSalesText && (
          <div
            style={{ fontWeight: 'bold', color: eventTextColor }}
            onClick={e => {
              this.props.setListingStatusIds([5]);
              this.handleCardClick(e);
            }}
          >
            <span className="fa fa-car" />
            <span style={{ paddingLeft: 6, whiteSpace: 'pre' }}>
              {noSalesText}
            </span>
          </div>
        )}
      </div>
    );
  }

  renderMarketplaceName() {
    const eventTextColor = getTemplate(
      this.props.template,
      'eventCardText.color'
    );

    return (
      <span style={{ color: eventTextColor }}>
        <Dotdotdot clamp={1}>
          {this.props.item.marketplace.marketplaceName}
        </Dotdotdot>
      </span>
    );
  }

  renderEventTime() {
    const adj = isDST() ? '07:00' : '08:00';
    const timezone = jstz.determine().name();
    const liveStartDate = moment
      .tz(`${this.props.item.liveStartDate}-${adj}`, timezone)
      .format('L h:mm A z');
    const endDate = this.props.item.eventEndTime;

    const eventTextColor = getTemplate(
      this.props.template,
      'eventCardText.color'
    );
    return (
      <>
        <span style={{ color: eventTextColor }}>{liveStartDate}</span>
        {false && (
          <CustomCountdown startTime={liveStartDate} endTime={endDate} />
        )}
      </>
    );
  }

  renderFooter() {
    const searchLink = `/search?eventName[0]=${encodeURIComponent(
      this.props.item.eventName
    )}`;
    const runlistLink = `/runlist/${this.props.item.eventId}`;

    return (
      <div style={styles.footer}>
        <Link
          to={runlistLink}
          style={styles.button}
          className="btn btn-primary"
        >
          <span className="fa fa-list" />
          <span style={{ paddingLeft: 4 }}>Runlist</span>
        </Link>
        <Link to={searchLink} style={styles.button} className="btn btn-primary">
          <span className="fa fa-search" />
          <span style={{ paddingLeft: 4 }}>Search</span>
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div className="event-card" style={styles.container}>
        <div style={styles.content}>
          {this.renderHeader()}
          {this.renderImage()}
          {this.renderDivider()}

          <div style={styles.eventInfo}>
            {this.renderCounts()}
            {this.renderMarketplaceName()}
            {this.renderEventTime()}
          </div>
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

EventCard.propTypes = {
  item: PropTypes.object,
  template: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  homePage: PropTypes.bool,
  setListingStatusIds: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { marketplaceFeatures, template } = state.entities;
  return { marketplaceFeatures, template };
};

export default connect(mapStateToProps, {
  setListingStatusIds,
})(EventCard);
