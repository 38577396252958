import React from "react";
import { getConfig } from "../utils/helpers";

const EventsNav = props => {
  var defaultSortValue =
    props.sortByEvent === ""
      ? getConfig("eventSort") === "alphabet sort"
        ? "A - Z"
        : "Start Time"
      : props.sortByEvent;
  return (
    <div className="search-nav">
      <div className="container-fluid">
        <div className="events-nav__search_title search-title pull-left">
          <h4>Events</h4>
        </div>
        <div className="col-md-4 pull-left filter-selector">
          <input
            className="form-control"
            onChange={props.searchHandler}
            placeholder="Search here..."
            style={{ height: "30px !important" }}
          />
        </div>

        <div className="sort-selector">
          <select value={defaultSortValue} onChange={props.sortHandler}>
            <option value="A - Z">A - Z</option>
            <option value="Start Time">Start Time</option>
            <option value="Lane">Lane</option>
          </select>
        </div>

        <div className="col-md-4 sk-toggle filter-selector">
          <div
            className={
              !props.showLiveEvent
                ? "sk-toggle-option sk-toggle__item is-active"
                : "sk-toggle-option sk-toggle__item"
            }
            onClick={props.handleToggleSwitch}
            style={{ padding: "4px 8px" }}
          >
            <div className="sk-toggle-option__text">
              <i className="fa fa-search" /> All Events
            </div>
          </div>
          <div
            className={
              props.showLiveEvent
                ? "sk-toggle-option sk-toggle__item is-active"
                : "sk-toggle-option sk-toggle__item"
            }
            onClick={props.handleToggleSwitch}
            style={{ padding: "4px 8px" }}
          >
            <div className="sk-toggle-option__text">
              <i className="fa fa-search" /> Live Events
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventsNav;
