import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import { loadSaveItemNotes } from '../../actions';
import { getConfig } from '../../utils/helpers';

const styles = {
  _deprecated__customStyles: { /* 2024.8.28 - moved to 'assets/css/styles.css */
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 300,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  textarea: {
    textAlign: 'left',
    minHeight: 150,
    maxHeight: 700,
    minWidth: '100%',
    maxWidth: '100%',
    border: 'none',
    backgroundColor: '#F5F5F5',
  },
};

class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes,
    };
  }

  onOpen = () => {
    this.setState({ notes: this.props.notes });
  };

  onClose = () => {
    this.setState({ notes: '' }, () => {
      this.props.onClose();
    });
  };

  handleAddNote = () => {
    const mpId = getConfig('marketplaceId');
    const { notes } = this.state;
    const { item } = this.props;

    this.props
      .loadSaveItemNotes(mpId, item.itemId, notes)
      .then(({ response }) => {
        if (response.wsStatus === 'Error') {
          toastr.error('Error', response.wsMessage);
        } else if (response.wsStatus === 'Success') {
          toastr.success('Success', response.wsMessage);
          this.onClose();
          this.props.loadData();
          this.setState({ notes: '' });
        }
      });
  };

  handleDeleteNote = () => {
    this.setState({ notes: '' }, () => {
      this.handleAddNote();
    });
  };

  handleChange = e => {
    this.setState({ notes: e.target.value });
  };

  renderDivider() {
    return <hr className='marketplace-reusable__modal-divider' />;
  }

  renderHeader() {
    return (
      <div className='marketplace-reusable__modal-header'>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-file" />
          <span style={{ marginLeft: 10 }}>Note</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderFooter() {
    const { notes } = this.state;
    const isDisabled = !notes;

    return (
      <div className='marketplace-reusable__modal-footer'>
        <div>
          <button
            className="btn btn-primary button-action"
            style={{ minWidth: 100, marginRight: 10 }}
            type="button"
            disabled={isDisabled}
            onClick={this.handleDeleteNote}
          >
            Delete
          </button>
        </div>
        <div className='marketplace-reusable__modal-buttons'>
          <button
            className="btn btn-primary button-cancel"
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary button-action"
            style={{
              opacity: isDisabled ? 0.5 : 1,
              minWidth: 100,
            }}
            type="button"
            disabled={isDisabled}
            onClick={this.handleAddNote}
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        className={'marketplace-reusable__modal-content'}
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Notes Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          <div style={styles.body}>
            <textarea
              // style={styles.textarea} /* moved to 'assets/css/styles.css' */
              className="form-control marketplace-reusable__modal-textarea"
              cols="40"
              rows="5"
              placeholder="Note"
              value={this.state.notes}
              onChange={this.handleChange}
            />
          </div>
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

AddNoteModal.propTypes = {
  item: PropTypes.object.isRequired,
  modalId: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
};

export default connect(null, { loadSaveItemNotes })(AddNoteModal);
