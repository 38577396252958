import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { find } from 'lodash';
import {
  commafyCurrency,
  getConfig,
  makeReadableDate,
  mergeBidHistory,
} from '../../utils/helpers';
import 'bootstrap';
import { loadBidHistory } from '../../actions';

const styles = {
  customStyles: {
    _deprecated__content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 200,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
};

/**
 * @class
 */
class BidHistoryModal extends Component {
  /**
   * @constructor
   * @param {{itemId:string, onClose:boolean, onOpen:()=>void, isOpen:boolean}} props 
   */
  constructor (props) {
    super();
    this.state = {
      bids: null,
      isLoading: true,
      itemId: props.itemId
    }
  }
  
  componentDidMount() {
    this.loadBidHistoryData(this.state.itemId);
  }

  /**
   * @description Fetches bid-history of given `itemId`.  Updates component state
   * @param {string} itemId id of item to use in bid-history fetch
   * @method
   */
  loadBidHistoryData = (itemId) => {
    if (itemId) {
      this.props.loadBidHistory(getConfig('marketplaceId'), itemId)
        .then(itemBidHistory => {
          this.setState({
            isLoading: false,
            bids: itemBidHistory?.response?.data || []
          })
        })
    }
  }

  onOpen = () => {
    //
  };

  onClose = () => {
    this.props.onClose();
  };

  renderDivider() {
    return <hr className='marketplace-reusable__modal-header' />;
  }

  renderHeader() {
    return (
      <div className='marketplace-reusable__modal-header'>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-history" />
          <span style={{ marginLeft: 10 }}>Bid History</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderContent() {
    const {itemId} = this.props;
    const realtimeBidHistoryData = this.props.bidMessageHistoryRealTime || [];
    if (this.state.isLoading) {
      return (
        <div style={styles.body}>
          <h4 style={{textAlign: 'center'}}>Loading ...</h4>
        </div>
      )
    }
    if (!itemId || (!this.state.bids?.length && !realtimeBidHistoryData.length)) {
      return (
        <div style={styles.body}>
          <h4>No Bid History available for this item</h4>
        </div>
      );
    }
    const mergedBidHistory = mergeBidHistory(
        itemId,
        this.state.bids,
        realtimeBidHistoryData
    )

    const accountList = this.props.userProfile.user
      ? this.props.userProfile.user.accountList
      : [];
    const hideBidder = getConfig('hideBidderInBidHistory');

    if (!mergedBidHistory.length && !this.state.isLoading) {
      return (
        <div style={styles.body}>
          <h4>No Bid History available for this item</h4>
        </div>
      );
    }

    return (
        <table className="table table-striped">
          <tbody>
            <tr style={{ borderTop: '1.1px solid gray' }}>
              <th>Amount</th>
              <th>Type</th>
              <th>Date</th>
              <th>{hideBidder ? '' : 'Bidder'}</th>
            </tr>
            {mergedBidHistory.map((bid, index) => {
              const yourAccount = find(
                accountList,
                acct => Number(acct.userId) === Number(bid.bidderId)
              );

              const bidderInfo = getConfig('hideBidderInBidHistory')
                ? yourAccount
                  ? 'Your Bid'
                  : ``
                : `${
                    yourAccount
                      ? 'You'
                      : bid.bidderName ||
                        `${yourAccount.mappedUserFirstName} ${yourAccount.mappedUserLastName}`
                  } (${bid.accountName || yourAccount.accountName})`;

              return (
                <tr key={index}>
                  <td>{commafyCurrency(bid.bidAmount)}</td>
                  <td>{bid.bidType}</td>
                  <td>{makeReadableDate(bid.dateCreated)}</td>
                  <td>{bidderInfo}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
    );
  }

  renderFooter() {
    return (
      <div className='marketplace-reusable__modal-footer'>
        <div />
        <div className='marketplace-reusable__modal-buttons'>
          <button
            className="btn btn-primary button-cancel"
            style={{ minWidth: 100 }}
            type="button"
            onClick={this.onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {

    return (
      <Modal
      className={'marketplace-reusable__modal-content'}
        isOpen={this.props.isOpen}
        style={styles.customStyles} /* 2024.8.28 moved to 'assets/css/styles.css' */
        contentLabel="Bid History Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
        shouldCloseOnEsc={true}
        preventScroll={true}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          {this.renderContent()}
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

BidHistoryModal.propTypes = {
  itemId: PropTypes.string,
  userProfile: PropTypes.object,
  bidMessageHistoryRealTime: PropTypes.array,
};

const mapStateToProps = state => {
  const { bidMessageHistoryRealTime } = state.entities;
  return { bidMessageHistoryRealTime };
};

export default connect(mapStateToProps, {loadBidHistory})(BidHistoryModal);
