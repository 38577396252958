import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { Button } from 'semantic-ui-react';
import { fetchGetShippingQuote, resetShippingQuote } from '../../actions/index.js'
import { useDispatch, useSelector } from "react-redux";
import { commafyCurrency } from '../../utils/helpers/index.js';
import { isEmpty } from 'lodash';

export default function TransportationQuote({destinationZip, mpId, itemData}) {

    const styles = {
        transportationInput: {
            backgroundClip: 'padding-box',
            backgroundColor: '#fff',
            border: '1px solid #a9a9a9',
            borderRadius: 4,
            maxWidth: '100%',
            padding: '3px 6px',
            marginTop: 0,
            verticalAlign: 'middle',
            width: 100,
            display: 'block',
            height: 40,
        },
        transportationQuoteButton: {
          borderRadius: 4,
          width: 'auto',
          color: 'white',
          backgroundColor: 'black',
          height: 40,
        },
        transportationQuoteContainer: {
          // display: 'flex',
          // alignItems: 'flex-end',
          // gap: 15,
          // paddingLeft: 10,
        }
    };

    const dispatch = useDispatch();
    const [zip, setZip] = useState(destinationZip);
    const shippingQuote = useSelector((state) => state.entities.shippingQuote);

    useEffect(() => {
        dispatch(resetShippingQuote());
      }, [itemData.vin, dispatch]);

    const hasShippingQuote = !isEmpty(shippingQuote);
    const hasShippingQuoteError =
      hasShippingQuote && shippingQuote.errorMessage;
    const errorMessage = 'Unable to provide shipping quote at this time';

    return (
      <>
        <div className='marketplace__modal_transport-acertus_container' style={{ paddingLeft: 10 }}>
          <label style={{ paddingBottom: 2.5 }} htmlFor="destinationZip">
            Enter your zip code and get an instant quote
          </label>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: 15 }}>
            <input
              className='marketplace__modal_transport-input_zip'
              id="destinationZip"
              type="text"
              style={styles.transportationInput}
              placeholder="Destination ZIP"
              value={zip}
              onChange={e => {
                setZip(e.target.value.replace(/\D/g, ''));
              }}
            />

            <Button
              style={styles.transportationQuoteButton}
              disabled={!zip}
              onClick={() => {
                const { itemId, vehicleZip } = itemData;

                if (!vehicleZip) {
                  toastr.error(
                    'Shipping quote error',
                    'Origin ZIP code not found'
                  );
                } else if (!zip) {
                  toastr.error(
                    'Shipping quote error',
                    'Destination ZIP code not found'
                  );
                } else {
                  dispatch(resetShippingQuote());
                  dispatch(fetchGetShippingQuote(
                    mpId,
                    itemId,
                    vehicleZip,
                    zip));
                }
              }}
            >
              Get Quote
            </Button>
          </div>
        </div>

        {!hasShippingQuote ? null : hasShippingQuoteError ? (
          <div 
            className='marketplace__modal_transport-acertus_quote' 
            // style={styles.transportationQuoteContainer}
          >
            <div style={{ color: 'red' }}>{errorMessage}</div>
          </div>
        ) : (
          <div 
            className='marketplace__modal_transport-acertus_quote'
            // style={styles.transportationQuoteContainer}
          >
            <div style={{ fontWeight: 700, margin: 'auto 0' }}>Shipping Quote</div>
            <div style={{ margin: 'auto 0' }}>{commafyCurrency(shippingQuote.rate)}</div>
          </div>
        )}

        </>
    );
}