import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { find, get, isEmpty } from 'lodash';
import Modal from 'react-modal';
import { loadItemBuy, loadWatchlistObject, resetBuy } from '../actions';
import { commafy, commafyCurrency, getConfig } from '../utils/helpers';
import Fees from './common/Fees';
import IDHorizontal from './common/IDHorizontal';

const styles = {
  customStyles: {
    _deprecated__content: { /* moved to 'assets/css/style.css' */
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 300,
      maxHeight: 800,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  pseudoDropdown: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '6px 16px',
    cursor: 'default',
    color: '#555',
  },
  link: {
    color: '#4183c4',
  },
};

class BuyModal extends Component {
  state = {
    accountList: [],
    selectedAccount: {},

    paymentTypes: [],
    selectedPayment: '',

    isSubmitting: false,
    agreed: !!getConfig('agreeToTermsAtLogin'),

    hideAccountsWithoutBidderBadges: undefined,
    hideFees: undefined,
    distanceInKM: undefined,

    loaded: false,
  };

  onOpen = () => {
    this.loadFeatures();
  };

  loadFeatures = () => {
    const mpFeatures = (this.props.marketplaceFeatures.features || '').split(
      ','
    );

    this.setState(
      {
        hideAccountsWithoutBidderBadges: mpFeatures.includes('469'),
        hideFees: mpFeatures.includes('548'),
        distanceInKM: mpFeatures.includes('389'),
      },
      () => {
        this.loadAccounts();
      }
    );
  };

  loadAccounts = () => {
    if (this.props.userProfile.user) {
      this.setState(
        prevState => {
          const accountList = (
            this.props.userProfile.user.accountList || []
          ).filter(account => {
            const isPersonalAccount = account.accountNumber.includes('@');
            const missingRequiredBadge =
              this.state.hideAccountsWithoutBidderBadges &&
              account.badgeNumber === null;
            return isPersonalAccount || missingRequiredBadge ? false : true;
          });

          const selectedAccount =
            prevState.selectedAccount && prevState.selectedAccount.accountId
              ? prevState.selectedAccount
              : accountList[0] || {};

          return { accountList, selectedAccount };
        },
        () => {
          this.loadPayments();
        }
      );
    }
  };

  loadPayments = () => {
    const { selectedAccount } = this.state;
    const paymentTypes = (selectedAccount.paymentTypesArray || []).filter(
      type => !['Pay Later', 'PayLater'].includes(type)
    );
    // paymentTypes.unshift('Pay Later'); // puts Pay Later in every array as first option
    paymentTypes.push('Pay Later') // 2024.7.17 - adding Pay Later as last option

    const selectedPayment =
      paymentTypes.find(
        paymentType => paymentType === selectedAccount.defaultPaymentType
      ) || paymentTypes[0];

    this.setState({ paymentTypes, selectedPayment, loaded: true });
  };

  handleAccountSelect = e => {
    const userId = Number(e.target.value);
    const selectedAccount = find(this.state.accountList, { userId }) || {};

    this.setState({ selectedAccount }, () => {
      this.loadPayments();
    });
  };

  handlePaymentSelect = e => {
    const selectedPayment = e.target.value;

    if (selectedPayment) {
      this.setState({ selectedPayment });
    }
  };

  handleBuy = e => {
    this.setState({ isSubmitting: true }, () => {
      const mpId = getConfig('marketplaceId');
      const itemId = this.props.item.itemId || this.props.item.id;
      const amount = this.props.item.outrightPrice;
      const userId = this.state.selectedAccount.userId;
      const paymentType =
        !this.state.selectedPayment ||
        this.state.selectedPayment === 'Pay Later'
          ? ''
          : this.state.selectedPayment;
      const transportationFeeId = 0;
      const transportFee = 0;

      this.props
        .loadItemBuy(
          mpId,
          itemId,
          amount,
          userId,
          paymentType,
          transportationFeeId,
          transportFee
        )
        .then(({ response }) => {
          if (response) {
            if (response.wsStatus === 'Success') {
              this.onBuySuccess(response);
            } else {
              this.onBuySuccess(response);
            }
          } else {
            throw new Error('Buy Error');
          }
        })
        .catch(error => this.onBuyError(error));
    });
  };

  onBuySuccess(response) {
    const mpId = getConfig('marketplaceId');
    this.props.loadWatchlistObject(mpId);
    toastr.success(response.wsStatus, response.wsMessage);
    this.onClose();

    this.setState(
      {
        isSubmitting: false,
        agreed: !!getConfig('agreeToTermsAtLogin'),
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetBuy();
        }, 1000);
      }
    );
  }

  onBuyWarning(response) {
    toastr.warning('Attention', response.wsMessage, { icon: 'warning' });

    this.setState(
      {
        isSubmitting: false,
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetBuy();
        }, 1000);
      }
    );
  }

  onBuyError(error) {
    console.error(error);
    toastr.error('Error', error.message || 'An unknown error occured');
    this.onClose();

    this.setState(
      {
        isSubmitting: false,
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetBuy();
        }, 1000);
      }
    );
  }

  onClose = () => {
    this.setState(
      {
        agreed: !!getConfig('agreeToTermsAtLogin'),
      },
      () => {
        this.props.onClose();
      }
    );
  };

  renderAccountSelect() {
    // if no authorized accounts
    if (!this.state.accountList.length) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? 'red' : 'transparent',
            }}
          >
            No Authorized Accounts
          </div>
        </div>
      );
    }

    // if single option don't use a dropdown
    if (this.state.accountList.length === 1) {
      const { accountName, city, state } = this.state.selectedAccount;

      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div style={styles.pseudoDropdown}>
            {`${accountName} – ${city}, ${state}`}
          </div>
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.accountList || []).map((account, index) => {
      const text = `${account.accountName} – ${account.accountNumber} – ${account.city}, ${account.state}`;

      return (
        <option key={index} value={account.userId}>
          {text}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="buyer-account">Buyer Account</label>
        <select
          id="buyer-account"
          className="form-control"
          onChange={this.handleAccountSelect}
          value={this.state.selectedAccount.userId}
        >
          {options}
        </select>
      </div>
    );
  }

  renderPaymentSelect() {
    if (
      getConfig('hideSinglePaymentOption') &&
      this.state.paymentTypes.length <= 2 // if hideSinglePaymentOption feature is enabled you must have more than just Pay Later
    ) {
      return null;
    }

    // if single option don't use a dropdown
    if (this.state.paymentTypes.length < 2) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Payment Type</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? '#555' : 'transparent',
            }}
          >
            {this.state.selectedPayment || '-'}
          </div>
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.paymentTypes || []).map((item, index) => (
      <option key={index} value={item}>
        {item}
      </option>
    ));

    return (
      <div className="form-group">
        <label htmlFor="payment-types">Payment Type</label>
        <select
          id="payment"
          className="form-control"
          onChange={this.handlePaymentSelect}
          value={this.state.selectedPayment}
        >
          {options}
        </select>
      </div>
    );
  }

  renderBasicInfo() {
    const { item } = this.props;
    const name = `${item.year} ${item.make} ${item.model}`;
    const itemLocation =
      item.itemCity && item.itemState
        ? `${item.itemCity}, ${item.itemState}`
        : item.vehicleCity && item.vehicleState
        ? `${item.vehicleCity}, ${item.vehicleState}`
        : '';

    return (
      <div>
        <h4 style={{ margin: '5px 0' }}>{name}</h4>
        <div style={{ margin: '5px 0' }}>
          <IDHorizontal
            vin={item.vin || item.vIN}
            stockNumber={item.stockNumber}
          />
        </div>
        {itemLocation && (
          <div style={{ margin: '5px 0' }}>
            <strong style={{ marginRight: 4 }}>Location:</strong>
            <span>{`${itemLocation}`}</span>
          </div>
        )}
      </div>
    );
  }

  renderBuyNowPrice() {
    const buyNowPrice = this.props.item.outrightPrice || this.props.item.buyNow;

    return (
      <div style={{ margin: '15px 0' }}>
        <h4
          style={{
            color: buyNowPrice ? '#016b19' : 'transparent', // keeps the spacing
          }}
        >
          {`Buy Now Price: ${commafyCurrency(buyNowPrice)}`}
        </h4>
      </div>
    );
  }

  renderFees() {
    if (!this.state.hideFees) {
      return (
        <div>
          {/* <div style={{ margin: '0 5px 15px 5px' }}>
            <strong>* Normal fees apply for simulcast auctions.</strong>
          </div> */}
          <Fees
            amount={this.props.item.outrightPrice}
            type="1"
            itemId={this.props.item.itemId || this.props.item.id}
            userId={this.state.selectedAccount?.userId || ''}
          />
        </div>
      );
    }
  }

  renderTermsAndConditions() {
    const { customerTermsLink } = this.props;

    return (
      <div className="form-group">
        <input
          type="checkbox"
          id="agree"
          checked={this.state.agreed}
          disabled={getConfig('agreeToTermsAtLogin')}
          onChange={e => {
            this.setState(prevState => ({ agreed: !prevState.agreed }));
          }}
        />
        {customerTermsLink ? (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the{' '}
            <a
              href={customerTermsLink}
              target="_blank"
              rel="noreferrer"
              style={styles.link}
            >
              {`${getConfig('shortName')} Terms and Conditions`}
            </a>
          </label>
        ) : (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the Terms and Conditions
          </label>
        )}
      </div>
    );
  }

  renderDivider() {
    return <hr className='marketplace-reusable__modal-divider' />;
  }

  renderHeader() {
    return (
      <div className='marketplace-reusable__modal-header'>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Buy Now</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderFooter() {
    const {
      agreed,
      isSubmitting,

      selectedAccount,
      selectedPayment,
    } = this.state;

    const isDisabled =
      isSubmitting || !agreed || !selectedAccount.userId || !selectedPayment;

    const buttonText = 'Buy';

    return (
      <div className='marketplace-reusable__modal-footer'>
        <div />
        <div className='marketplace-reusable__modal-buttons'>
          <button
            className="btn btn-primary button-cancel"
            style={{
              opacity: isSubmitting ? 0.5 : 1,
              minWidth: 100,
              marginRight: 10,
            }}
            disabled={isSubmitting}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary button-action"
            style={{
              opacity: isDisabled ? 0.5 : 1,
              minWidth: 100,
            }}
            type="button"
            disabled={isDisabled}
            onClick={this.handleBuy}
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.item || !this.props.userProfile.user) {
      return null;
    }

    return (
      <Modal
        className={'marketplace-reusable__modal-content'}
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Buy Now Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          <div style={styles.body}>
            {this.renderBasicInfo()}
            {this.renderBuyNowPrice()}
            <form>
              {this.renderFees()}
              {this.renderAccountSelect()}
              {this.renderPaymentSelect()}
              {this.renderTermsAndConditions()}
            </form>
          </div>
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

BuyModal.defaultProps = {
  loadData: () => null,
};

BuyModal.propTypes = {
  customerTermsLink: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { customerTermsLink, marketplaceFeatures, userProfile } =
    state.entities;
  return { customerTermsLink, marketplaceFeatures, userProfile };
};

export default connect(mapStateToProps, {
  resetBuy,
  loadItemBuy,
  loadWatchlistObject,
})(BuyModal);
