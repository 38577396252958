import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const styles = {
  modalStyles: {
    content: {
      // top: '40%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
      // backgroundColor: 'white',
      // zIndex: 99,
      // position: 'absolute',
      // borderRadius: '8px',
      // border: 'none',
      padding: 0,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 99,
    },
  },
};

export default class IASConnectModal extends Component {
  render() {
    return (
      <Modal
        id='mp-auth-connect-modal'
        className={'marketplace-reusable__modal-content'}
        isOpen={this.props.isOpen}
        style={styles.modalStyles}
        contentLabel="Connect"
        onRequestClose={this.props.onClose}
        ariaHideApp={false}
      >
        <div
          className='mp-auth-connect__wrapper'
          // style={{ maxWidth: window.innerWidth, minWidth: 'auto', width: 840 }}
        >
          <div style={{ overflow: 'hidden', borderRadius: 5 }}>
            <iframe
              title="iasconnect"
              // style={{ display: 'flex', height: 660, width: '100%' }}
              src={this.props.url}
              frameBorder="0"
              allowFullScreen={true}
              referrerPolicy="no-referrer"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

IASConnectModal.defaultProps = {
  onClose: () => null,
};

IASConnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  url: PropTypes.string,
};
